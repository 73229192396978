.datePickerInput {
  width: 100%;
    color: var(--black);
    font-size: 16px;
    line-height: 155%;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px 16px;
    outline: none;

}
